<template>
    <div id="fourteen" style="width: 100%; height: 300px;"></div>
</template>
 
<script>
import * as echarts from 'echarts';
import { fourteenn } from "../../../api/compoents";
export default {
    data() {
        return {

        }
    },
    mounted() {
        this.$nextTick(() => {
            fourteenn()
            .then((res) => {
                this.fourteen(res.data.peopleNum, res.data.ok, res.data.no)
            })
            .catch((err) => {
                console.log(err)
            })
        })
    },
    methods: {
        fourteen(aa,bb,cc) {
            let chartDom = document.getElementById('fourteen');
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                title: {
                    // 暨2022山东省高新视频创新大赛颁奖典礼
                    text: "2023山东省高新视频创新大赛启动仪式",
                    right: "center",
                    // top: "20px",
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    data: [
                        '确定参加',
                        '暂不确定',
                        '全部'
                    ],
                    orient: "vertical",
                    left: "left",
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '30%'],
                        label: {
                            position: 'inner',
                            fontSize: 14
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: bb, name: '确定参加' },
                            { value: cc, name: '暂不确定' },
                            // { value: 679, name: 'Marketing', selected: true }
                        ]
                    },
                    {
                        name: '',
                        type: 'pie',
                        radius: ['45%', '60%'],
                        labelLine: {
                            length: 30
                        },
                        data: [
                            { value: aa, name: '全部' },
                        ]
                    }
                ]
            };

            option && myChart.setOption(option);
        }
    },

}
</script>
 
<style scope></style>