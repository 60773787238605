<template>
  <div class="wrap">
    <!-- 数据统计 -->
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>数据统计</span>
      </div>

      <el-row>
      <el-col :span="12" >
            <div class="grid-content bg-purple">
              <fifteen></fifteen>
            </div>
          </el-col>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
             <sixteen></sixteen> 
            </div>
          </el-col>
     </el-row>

      <el-row>
      <el-col :span="12" >
            <div class="grid-content bg-purple">
              <thirteen></thirteen>
            </div>
          </el-col>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
             <fourteen></fourteen> 
            </div>
          </el-col>
     </el-row>

      <el-row>
      <el-col :span="12" >
            <div class="grid-content bg-purple">
              <eleven></eleven>
            </div>
          </el-col>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
             <twelve></twelve> 
            </div>
          </el-col>
     </el-row>


      <el-row>
      <el-col :span="12" >
            <div class="grid-content bg-purple">
              <nine></nine>
            </div>
          </el-col>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
             <ten></ten> 
            </div>
          </el-col>
     </el-row>


      <el-row>
      <el-col :span="12" >
            <div class="grid-content bg-purple">
              <seven></seven>
            </div>
          </el-col>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
             <eight></eight> 
            </div>
          </el-col>
     </el-row>

      <el-row>
      <el-col :span="12" >
            <div class="grid-content bg-purple">
              <five></five>
            </div>
          </el-col>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
             <six></six> 
            </div>
          </el-col>
     </el-row>

      <el-row>
      <el-col :span="12" >
            <div class="grid-content bg-purple">
              <three></three>
            </div>
          </el-col>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
             <four></four> 
            </div>
          </el-col>
     </el-row>
      
     <el-row>
      <el-col :span="12" >
            <div class="grid-content bg-purple">
              <one></one>
            </div>
          </el-col>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
              <two></two>
            </div>
          </el-col>
     </el-row>
    
      <el-row>
        <el-row>
          <el-col :span="12" >
            <div class="grid-content bg-purple">
              <dili></dili>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content bg-purple-light">
               <actualtime></actualtime>
              </div>
            </el-col>
        </el-row>
        
        <el-col :span="12"
          ><div class="grid-content bg-purple"></div>
          <div
            id="main"
            style="
              width: 600px;
              height: 325px;
              margin-bottom: 10px;
              float: left;
              background-color: rgb(250, 250, 250);
              display: inline-block;
            "
          ></div
        ></el-col>
        <el-col :span="12">
          <div>
            <div
              id="mainall"
              style="height: 320px; background-color: rgb(250, 250, 250)"
            ></div>
          </div>
          <!-- <dili></dili> -->
        </el-col>
        <!-- <div class="grid-content bg-purple-light">
            <div class="right_zs fr">
              <div class="data-box1 right_tb">
                <div class="data-title">
                  <b class="data-title-left">[</b>
                  <span>参会状态表</span>
                  <b class="data-title-right">]</b>
                </div>

                <div class="ryclts">
                  <div id="chzt" style="height: 362px"></div>
                </div>
              </div>
            </div></div
        > -->
      </el-row>
      <div class="text item">
        <el-row>
          <div
            v-for="(item, index) in chartData1"
            :ref="'chart1' + index"
            :id="'chart1' + index"
            class="item-box"
            style="height: 340px; width: 340px; display: inline-block"
          ></div>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<script language="JavaScript" src="js/jquery.min.js"></script>
<script language="JavaScript" src="js/echarts.min.js"></script>
<script language="JavaScript" src="js/monitor_sw.js"></script>
<script>
import {
  getDlStatusCount,
  getDataCount,
  getQdCount,
  getChartData1,
  getyiqingChart,
  getshenheChart,
} from "../../api/home";
import { getToken } from "../../common/utils/auth";
import util from "../../common/utils/util";
import * as echarts from "echarts";
import canhui from "./compont/index.vue";
import dili from "./components/didi.vue";
import actualtime from "./components/actualtime";
import one from "./components/one";
import two from "./components/two";
import three from "./components/three";
import four from "./components/four";
import five from "./components/five";
import six from "./components/six";
import seven from "./components/seven";
import eight from "./components/eight";
import nine from "./components/nine";
import ten from "./components/ten";

import eleven from "./components/eleven";
import twelve from "./components/twelve";
import thirteen from "./components/thirteen";
import fourteen from "./components/fourteen";
import fifteen from "./components/fifteen";
import sixteen from "./components/sixteen";

export default {
  name: "home",
  components: { canhui, dili, actualtime, one, two,three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen},
  data() {
    return {
      myChart3: "",
      opinion3: ["嘉宾人数", "确认参加", "总数"],
      opinionData3: [
        { value: 13, name: "确认参加", itemStyle: "green" },
        { value: 19, name: "嘉宾人数", itemStyle: "#1ab394" },
        {
          value: 1911,
          name: "wqe",
          itemStyle: "blue",
        },
      ],
      series: [
        { value: 2, name: "全部人数" },
        { value: 3, name: "审核中" },
        { value: 5, name: "审核通过" },
        { value: 7, name: "未通过" },
      ],
      seriestwo: [
        {
          name: "参会状态人数",
          // data: [50, 48, 52, 45, 100, 100],
          data: [
            { value: 1023, name: "嘉宾总人数" },
            { value: 735, name: "确认参加人数" },
            { value: 580, name: " 审核中人数" },
            { value: 484, name: "拒绝参加人数" },
            { value: 300, name: "不确定人数" },
            { value: 300, name: "其他人数" },
          ],
          type: "line",
        },
      ],
      dlStatus: {
        v1: "加载中", // 未到
        v2: "加载中", // 在途
        v3: "加载中", // 到融创茂
        v4: "加载中", // 入住酒店
        v5: "加载中", // 已入场
        v6: "加载中", // 离店
        v7: "加载中", // 离青
      },
      dataCount: {
        v1: "加载中", // 未到
        v2: "加载中", // 在途
        v3: "加载中", // 到融创茂
        v4: "加载中", // 入住酒店
        v5: "加载中", // 已入场
        v6: "加载中", // 离店
        v7: "加载中", // 离青
        v8: "加载中", // 离青
        v9: "加载中", // 离青
        v10: "加载中", // 离青
      },
      qdCount: [], // 签到统计
      chartData1: [],
      myChart2: "",
      opinionData2: [
        { value: 0, name: "全部人数", itemStyle: "red" },
        { value: 0, name: "审核中", itemStyle: "#1FC48D" },
        { value: 0, name: "审核通过", itemStyle: "#6DC8EC" },
        { value: 0, name: "未通过", itemStyle: "#3F8FFF" },
      ],
    };
  },
  mounted() {
    this.getChartData1();
    // this.drawLine2();
  },
  created() {
    this.getshenheChart();
    this.getyiqingChart();
  },
  updated() {
    // 更新 chart1
    for (let i in this.chartData1) {
      if (this.$refs["chart1" + i]) {
        this.$nextTick(() => {
          this.drawChart1("chart1" + i, this.chartData1[i]);
        });
      }
    }
    this.dwdw();
    this.draw();
  },

  methods: {
    dwdw() {
      var chartDom = document.getElementById("mainall");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "嘉宾审核状态图表",
          subtext: "审核状态",
          right: "center",
          top: "20px",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "审核状态",
            type: "pie",
            radius: "50%",
            data: this.series,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    draw() {
      this.myChart = echarts.init(document.getElementById("main"));
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: "嘉宾参会状态",
          left: "center",
          top: "20px",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "参会状态",
            type: "pie",
            radius: "50%",
            // data: [
            //   { value: 1023, name: "解约失败" },
            //   { value: 735, name: "等待银行扣款结果中" },
            //   { value: 580, name: "解锁失败" },
            //   { value: 484, name: "接口数据错误" },
            //   { value: 300, name: "页面加载失败" },
            // ],
            data: this.seriestwo[0].data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      let _this = this; //注意，要将this进行指代，否则在click里面this指向发生了改变

      this.myChart.setOption(option);
      //点击事件
      this.myChart.on("click", function (params) {
        _this.$alert(params, "问题详情", {
          confirmButtonText: "确定",
          dangerouslyUseHTMLString: true,
          callback: (action) => {},
        });
      });
    },
    drawLine2() {
      // console.log("开始画饼图")
      // 基于准备好的dom，初始化echarts实例
      this.myChart3 = echarts.init(document.getElementById("myChart3"));
      // 绘制图表
      this.myChart3.setOption({
        title: {
          text: "全部嘉宾参会状态", // 主标题
          subtext: "", // 副标题
          x: "left", // x轴方向对齐方式
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",

          bottom: "bottom",
          data: this.opinion3,
        },
        series: [
          {
            name: "出勤数据",
            type: "pie",
            radius: "50%",
            center: ["50%", "50%"],
            data: this.opinionData3,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              color: function (params) {
                // 自定义颜色
                var colorList = ["red", "#1ab394", "blue"];
                return colorList[params.dataIndex];
              },
            },
          },
        ],
      });
    },
    drawChart2() {
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["疫情审核状态", "风险程度"],
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: [
          {
            type: "category",
            data: ["总数", "审核通过", "审核失败", "审核中"],
            axisLabel: {
              show: true,
              textStyle: {
                color: "#657c97",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "疫情审核状态",
            nameTextStyle: {
              color: "#657c97",
            },
            min: 0,
            max: 100,
            interval: 50,
            axisLabel: {
              textStyle: {
                color: "#657c97",
              },
            },
          },
        ],
        series: [
          {
            name: "疫情审核状态",
            type: "bar",

            /*设置柱状图颜色*/
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "orange" },
                  { offset: 0.5, color: "orange" },
                  { offset: 1, color: "orange" },
                ]),
                /*信息显示方式*/
                label: {
                  show: false,
                  position: "top",
                  formatter: "{b}\n{c}",
                },
              },
            },
            data: this.series[0].data,
          },
          // {
          //   name: "风险程度",
          //   type: "line",
          //   itemStyle: {
          //     /*设置折线颜色*/
          //     normal: {
          //       color: "#0ff",
          //     },
          //   },
          //   data: this.series[0].data,
          // },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("xjfxzt"));
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    drawChart3() {
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["嘉宾参会状态", "参会状态"],
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: [
          {
            type: "category",
            data: [
              "总数",
              "确认参加人数",
              "审核中多少人",
              "拒绝参加多少人",
              "不确定多少人",
              "其他",
            ],
            axisLabel: {
              show: true,
              textStyle: {
                color: "#657c97",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "嘉宾参会状态",
            nameTextStyle: {
              color: "#657c97",
            },
            min: 0,
            max: 600,
            interval: 50,
            axisLabel: {
              textStyle: {
                color: "#657c97",
              },
            },
          },
        ],
        series: [
          {
            name: "嘉宾参会状态",
            type: "bar",

            /*设置柱状图颜色*/
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "orange" },
                  { offset: 0.5, color: "orange" },
                  { offset: 1, color: "orange" },
                ]),
                /*信息显示方式*/
                label: {
                  show: false,
                  position: "top",
                  formatter: "{b}\n{c}",
                },
              },
            },
            data: this.seriestwo[0].data,
          },
          {
            name: "参会状态",
            type: "line",
            itemStyle: {
              /*设置折线颜色*/
              normal: {
                color: "#0ff",
              },
            },
            data: this.seriestwo[0].data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("chzt"));
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    drawChart1(tagId, data) {
      let tag = document.getElementById(tagId);
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(tag);
      // 绘制图表
      let option = {
        title: {
          text: data.title,
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}",
        },
        color: [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ],
        legend: {
          orient: "vertical",
          left: "left",
          bottom: "0px",
          data: data.legendData,
        },
        series: [
          {
            name: "",
            type: "pie",
            selectedMode: "single",
            radius: [0, "40%"],
            label: {
              formatter: "{b}\n{c}",
              position: "inner",
              fontSize: 12,
            },
            labelLine: {
              show: false,
            },
            data: data.data1,
          },
          {
            name: "",
            type: "pie",
            radius: ["50%", "70%"],
            labelLine: {
              length: 30,
            },
            label: {
              formatter: " {b|{b}：}{c} ",
              backgroundColor: "#F6F8FC",
              borderColor: "#8C8D8E",
              borderWidth: 1,
              borderRadius: 4,

              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 22,
                  fontSize: 12,
                  align: "center",
                },
                b: {
                  color: "#4C5058",
                  fontSize: 12,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
              },
            },
            data: data.data2,
          },
        ],
      };

      let toolbox = {
        show: true,
        feature: {
          myTool1: {
            show: true,
            title: "查看",
            icon: "path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891",
            onclick: () => {
              // alert('myToolHandler1'+tagId);
              // console.log("=============")
              // console.log(data);
              // console.log("=============")

              let item = {
                componentName: "yycompanyParticipateManage",
                icon: "",
                path:
                  "/device/yycompany_participate_manage/" + data.yyCompanyId,
                title: data.title,
              };
              this.$store.commit("openTab", {
                item: item,
                router: (path) => {
                  this.$router.replace(path);
                },
              });
            },
          },
        },
      };

      let rname = util.getCookie("rname");
      if (rname == "系统管理员") {
        option["toolbox"] = toolbox;
      }

      myChart.setOption(option);
    },
    getChartData1() {
      getChartData1()
        .then((res) => {
          if (res.code == 1) {
            this.chartData1 = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getyiqingChart() {
      getyiqingChart()
        .then((res) => {
          console.log(res, "shenhe");
          this.series[0].value = res.data.总数;
          this.series[1].value = res.data.审核通过;
          this.series[2].value = res.data.审核失败;
          this.series[3].value = res.data.审核中;

          console.log(this.series[3].value);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getshenheChart() {
      getshenheChart()
        .then((res) => {
          console.log(res, "hahah ");
          this.seriestwo[0].data[0].value = res.data.participateCount;
          this.seriestwo[0].data[1].value = res.data.participateConfirmCount;
          this.seriestwo[0].data[2].value =
            res.data.participateUnderReviewCount;
          this.seriestwo[0].data[3].value = res.data.participateRejectCount;
          this.seriestwo[0].data[4].value = res.data.participateUncertainCount;
          this.seriestwo[0].data[5].value = res.data.participateOtherCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDlStatusCount() {
      getDlStatusCount()
        .then((res) => {
          if (res.code == 1) {
            this.dlStatus = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDataCount() {
      getDataCount()
        .then((res) => {
          if (res.code == 1) {
            this.dataCount = res.data.dataCount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQdCount() {
      getQdCount()
        .then((res) => {
          if (res.code == 1) {
            this.qdCount = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    drawLine1() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart2 = echarts.init(document.getElementById("myChart2"));

      // 绘制图表
      this.myChart2.setOption({
        title: {
          text: "全部疫情管理", // 主标题
          subtext: "", // 副标题
          x: "left", // x轴方向对齐方式
        },
        tooltip: {
          trigger: "axis", // axis   item   none三个值
        },
        xAxis: {
          type: "category", // 还有其他的type，可以去官网喵两眼哦
          data: ["总人数", "审核通过", "审核未通过", "审核中"], // x轴数据
          name: "", // x轴名称
          // x轴名称样式
          // nameTextStyle: {
          //   fontWeight: 600,
          //   fontSize: 18
          // }
        },
        yAxis: {
          type: "value",
          name: "疫情管理人数", // y轴名称
          // y轴名称样式
          // nameTextStyle: {
          //   fontWeight: 600,
          //   fontSize: 18
          // }
        },
        legend: {
          orient: "vertical",
          x: "center",
          y: "top",
          data: ["疫情管理人数"],
        },
        series: this.series,
        // series: [
        //   {
        //     name: "疫情管理人数",
        //     data: [50, 48, 52, 45],
        //     type: "line",
        //   },
        // ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/common/styles/element-variables.scss";
@import "css/public.css"; //这个分号一定要写，要不会报错
@import "css/jianlisw.css"; //这个分号一定要写，要不会报错
// @import "css/public.css"; //这个分号一定要写，要不会报错

.wrap {
  // margin-top: 200px;
  width: 100%;
  color: $--color-info;
  //font-size: 50px;
  // text-align: center;
  //display: flex;
  //justify-content: center;
  //align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: auto;
  margin-bottom: 16px;
}

.item-box {
  width: auto;
  height: 100%;
  background-color: rgb(250, 250, 250);
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 15px;

  &:hover {
    background-color: #ddd;
  }

  .item-text {
    font-size: 12px;
    color: #999;
  }
  .item-num {
    margin-top: 10px;
    font-size: 22px;
    color: #409eff;
  }
}
</style>
<style>
.el-card__body {
  padding-bottom: 0px !important;
}
</style>
