<template>
  <div class="echart" id="mychart" :style="myChartStyle"></div>
</template>
  
  <script>
import * as echarts from "echarts";
import { distinguished } from "../../../api/compoents";

export default {
  data() {
    return {
      xData: ["政务贵宾", "政务嘉宾", "行业贵宾", "行业嘉宾"], //横坐标
      // yData: [23, 24, 18, 25], //人数数据
      // taskDate: [10, 11, 9, 17],
      // weizhi: [20, 8, 7, 9],
      yData: [], //人数数据
      taskDate: [],
      weizhi: [],
      myChartStyle: {
        float: "left",
        width: "100%",
        height: "380px",
        top: "100px",
      }, //图表样式
    };
  },
  created() {},
  mounted() {
    this.initEcharts();
  },
  methods: {
    async initEcharts() {
      let hh = await distinguished();
      console.log(hh.data);

      // 多列柱状图
      const mulColumnZZTData = {
        title: {
          text: "嘉宾抵青状态统计",
          right: "center",
        },
        xAxis: {
          data: this.xData,
        },
        // 图例
        legend: {
          data: ["未抵达人数", "抵达人数", "未知人数(未登记抵青时间)"],
          top: "5%",
        },
        yAxis: {},
        series: [
          {
            type: "bar", //形状为柱状图
            data: [
              hh.data[0].政务贵宾未到,
              hh.data[1].政务嘉宾未到,
              hh.data[2].行业贵宾未到,
              hh.data[3].行业嘉宾未到,
            ],
            name: "未抵达人数", // legend属性
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: true,
              position: "top",
            },
          },
          {
            type: "bar", //形状为柱状图
            data: [
              hh.data[0].政务贵宾已到,
              hh.data[1].政务嘉宾已到,
              hh.data[2].行业贵宾已到,
              hh.data[3].行业嘉宾已到,
            ],
            name: "抵达人数", // legend属性
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: true,
              position: "top",
            },
          },
          {
            type: "bar", //形状为柱状图
            data: [
              hh.data[0].政务贵宾未知,
              hh.data[1].政务嘉宾未知,
              hh.data[2].行业贵宾未知,
              hh.data[3].行业嘉宾未知,
            ],
            name: "未知人数(未登记抵青时间)", // legend属性
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: true,
              position: "top",
            },
          },
        ],
      };
      const myChart = echarts.init(document.getElementById("mychart"));
      myChart.setOption(mulColumnZZTData);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>
  