import request from "@/common/utils/request";
import Vue from "vue";

export function distinguished(data) {
  return request({
    url:
      Vue.prototype.$baseUrl +
      "/adminapi/participate/selectParticipateArrivalStatusTotal",
    method: "get",
    data
  });
}

export function actualtime(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/participate/selectAudience",
    method: "get",
    data
  });
}

export function onee(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount14",
    method: "get",
    data
  });
}

export function twoo(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount15",
    method: "get",
    data
  });
}

export function threee(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount13",
    method: "get",
    data
  });
}

export function fourr(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount4",
    method: "get",
    data
  });
}

export function fivee(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount2",
    method: "get",
    data
  });
}

export function sixx(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount3",
    method: "get",
    data
  });
}

export function sevenn(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount5",
    method: "get",
    data
  });
}

export function eightt(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount6",
    method: "get",
    data
  });
}

export function ninee(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount8",
    method: "get",
    data
  });
}

export function tenn(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount9",
    method: "get",
    data
  });
}

export function elevenn(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount7",
    method: "get",
    data
  });
}


export function twelvee(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount11",
    method: "get",
    data
  });
}

export function thirteenn(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount12",
    method: "get",
    data
  });
}

export function fourteenn(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount10",
    method: "get",
    data
  });
}

export function fifteenn(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount16",
    method: "get",
    data
  });
}

export function sixteenn(data) {
  return request({
    url:
      Vue.prototype.$baseUrl + "/adminapi/plate/plateCount13",
    method: "get",
    data
  });
}