<template>
    <div id="seven" style="width: 100%; height: 300px;"></div>
</template>
 
<script>
import * as echarts from 'echarts';
import { sevenn } from "../../../api/compoents";

export default {
    data() {
        return {

        }
    },
    mounted() {
        this.$nextTick(() => {
            sevenn()
            .then((res) => {
                this.seven(res.data.peopleNum, res.data.ok, res.data.no)
            })
            .catch((err) => {
                console.log(err)
            })
        })
    },
    methods: {
        seven(aa,bb,cc) {
            let chartDom = document.getElementById('seven');
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                title: {
                    text: "短剧主题论坛“短剧的'大淘金'时代下如何促进短剧的可持续化发展？”",
                    right: "center",
                    // top: "20px",
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    data: [
                        '确定参加',
                        '暂不确定',
                        '全部'
                    ],
                    orient: "vertical",
                    left: "left",
                    top: "30px"
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '30%'],
                        label: {
                            position: 'inner',
                            fontSize: 14
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: bb, name: '确定参加' },
                            { value: cc, name: '暂不确定' },
                            // { value: 679, name: 'Marketing', selected: true }
                        ]
                    },
                    {
                        name: '',
                        type: 'pie',
                        radius: ['45%', '60%'],
                        labelLine: {
                            length: 30
                        },
                        // label: {
                        //     formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                        //     backgroundColor: '#F6F8FC',
                        //     borderColor: '#8C8D8E',
                        //     borderWidth: 1,
                        //     borderRadius: 4,
                        //     rich: {
                        //         a: {
                        //             color: '#6E7079',
                        //             lineHeight: 22,
                        //             align: 'center'
                        //         },
                        //         hr: {
                        //             borderColor: '#8C8D8E',
                        //             width: '100%',
                        //             borderWidth: 1,
                        //             height: 0
                        //         },
                        //         b: {
                        //             color: '#4C5058',
                        //             fontSize: 14,
                        //             fontWeight: 'bold',
                        //             lineHeight: 33
                        //         },
                        //         per: {
                        //             color: '#fff',
                        //             backgroundColor: '#4C5058',
                        //             padding: [3, 4],
                        //             borderRadius: 4
                        //         }
                        //     }
                        // },
                        data: [
                            { value: aa, name: '全部' },
                            // { value: 335, name: 'Direct' },
                            // { value: 310, name: 'Email' },
                            // { value: 251, name: 'Google' },
                            // { value: 234, name: 'Union Ads' },
                            // { value: 147, name: 'Bing' },
                            // { value: 135, name: 'Video Ads' },
                            // { value: 102, name: 'Others' }
                        ]
                    }
                ]
            };

            option && myChart.setOption(option);
        }
    },

}
</script>
 
<style scope></style>