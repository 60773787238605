<template>
  <div id="actiual" style="width: 100%; height: 300px;"></div>
</template>

<script>
import * as echarts from 'echarts';
import { actualtime } from "../../../api/compoents";
export default {
  data() {
    return {

    }
  },
  mounted() {
    this.$nextTick(() => {
      actualtime()
        .then((res) => {
          console.log(res.data.count)
          this.drawPieChart(res.data.count, res.data.success, res.data.wait)

        })
        .catch((err) => {
          console.log(err)
        })


    })
  },
  methods: {
    drawPieChart(d,s,x) {
      let chartDom = document.getElementById('actiual');
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        title: {
          text: "实时数据概览",
          right: "center",
          top: "20px",
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          data: [
            '观众报名总人数',
            '已审核',
            '未审核'
          ],
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: '实时数据概览',
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            label: {
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: [
              { value: s, name: '已审核' },
              { value: x, name: '未审核' },
            ]
          },
          {
            name: '实时数据概览',
            type: 'pie',
            radius: ['45%', '60%'],
            labelLine: {
              length: 30
            },
            data: [
              { value: d, name: '观众报名总人数' },
            ]
          }
        ]
      };

      option && myChart.setOption(option);
    }
  },

}
</script>

<style scope></style>